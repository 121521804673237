<template>
  <div class="dev-body">
    <div class="dev-body-content">
      <Downloads />
      <DevFooter />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    Downloads: () => import("./Downloads.vue"),
    DevFooter: () => import("./DevFooter.vue"),
  },
};
</script>

<style lang="scss">
.dev-body {
  padding: $mpadding;
  &-content {
    max-width: 1200px;
    margin: 0 auto;
  }
  .collapse:not(.show) {
    display: block;
  }
  .collapse {
    border: solid 1px $alto;
    border-radius: $mpadding/4;
    .title {
      font-size: 130%;
    }
    &-header {
      padding: $mpadding;
      border-bottom: solid 1px $alto;
      &-right * {
        pointer-events: none;
        display: none;
      }
    }
    &-content {
      padding: $mpadding;
    }
  }
  @include screen($tv_width) {
    padding: $mpadding * 2;
  }
}
</style>
